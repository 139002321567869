import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./assets/styles/style.scss";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import quizApi from "./apiQuestions.json";
import quizProducts from "./apiProducts.json";
import Card from "react-bootstrap/Card";
import Lottie from "lottie-react";
import brainyAnimation from "./lotties/brainy-generic-animation.json";

export default function App() {
  const [mainMenu, setMainMenu] = useState(true);
  // Current question ID (we use id from API instead of index)
  const [currentQuestion, setCurrentQuestion] = useState(1);
  // When true results will be displayed
  const [showProducts, setShowProducts] = useState(false);
  // Array of products that are determined by user answer choice
  const [chosenProducts, setchosenProducts] = useState([]);
  // Array of chosen answers
  const [chosenAnswer, setchosenAnswer] = useState([]);
  // Recommended products preloader
  const [preLoader, setPreLoader] = useState(false);

  const handleAnswerOptionClick = (path, productName, answerText) => {
    const nextQuestion = path;

    // Add product to array based on chosen answer
    setchosenProducts((current) => [...current, productName]);

    // Check whether next question exists otherwise show results
    if (nextQuestion <= quizApi.content.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setPreLoader(true);
      setTimeout(() => {
        setPreLoader(false);
        setShowProducts(true);
      }, 7000);
    }
    setchosenAnswer((current) => [...current, answerText]);
  };

  // Find object of current question
  const filterCurrentQuestion = quizApi.content.find(
    (obj) => obj.id === currentQuestion
  );

  // Filter out products based on users' selection
  const filterProducts = quizProducts.filter((el) => {
    return chosenProducts.some((f) => {
      return f === el.productName;
    });
  });

  useEffect(() => {
    console.log("currentQuestion", currentQuestion);
  }, [currentQuestion]);

  const startQuiz = () => {
    setMainMenu(false);
    setCurrentQuestion(1);
    setShowProducts(false);
    setchosenAnswer([]);
  };

  const containerVariants = {
    hidden: {
      translateY: -35,
      opacity: 0,
    },
    visible: {
      translateY: 0,
      opacity: 1,
      transition: {
        duration: 1.05,
        staggerChildren: 0.26,
      },
    },
  };

  const child = {
    hidden: { translateY: 0, opacity: 0 },
    visible: { translateY: 1, opacity: 1 },
  };

  const MainMenu = () => {
    return (
      <div>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="container d-flex justify-content-center align-items-center bg-transparent"
        >
          <motion.h1 variants={child} className="h1-welcome mb-4 ">
            ULTIMATE GIFT FINDER
          </motion.h1>

          <motion.p variants={child} className="p-main ">
            Never bring boring gift again! Use this Ultimate Gift finder and get
            personalized gift results.
          </motion.p>
          <div className="welcome-card-icons text-center mx-auto bg-transparent">
            <motion.div variants={child} className="welcome-icon-1">
              <img className="icon" src="svg/question-icon.svg" />
              <p className="welcome-card-p pt-3">
                5 questions about your preferences
              </p>
            </motion.div>
            <motion.div variants={child} className="welcome-icon-2">
              <img className="icon" src="svg/like-icon.svg" />
              <p className="welcome-card-p pt-3">
                3 TOP<br></br> recommendation
              </p>
            </motion.div>
            <motion.div variants={child} className="welcome-icon-3">
              <img className="icon" src="svg/gift-icon.svg" />
              <p className="welcome-card-p pt-3">
                1.000+ different gifts to recommend
              </p>
            </motion.div>
          </div>
          <motion.button
            variants={child}
            className="btn btn-secondary rounded-pill mt-3"
            onClick={startQuiz}
          >
            Find gift
          </motion.button>
        </motion.div>
        <div className="d-flex justify-content-center align-items-center escapebox-btn-wrapper">
          <a
            className="btn escapebox"
            href="https://escapebox.si/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={process.env.PUBLIC_URL + "escapebox-engine-dark.svg"} />
          </a>
        </div>
      </div>
    );
  };

  const ShowQuestions = () => {
    return (
      <>
        {preLoader ? <PreLoader /> : null}

        <div className="questionMenu d-flex justify-content-center align-items-center">
          <div
            initial={{ opacity: 0, translateX: 100 }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: 100 }}
            transition={{ duration: 1, type: "tween" }}
            className="quiz-container d-flex justify-content-center align-items-center bg-transparent px-2"
          >
            <div id="stepProgressBar">
              <ul className="progress-bar-wrapper mb-2">
                <li
                  className={`stepBullet ${
                    currentQuestion === 1
                      ? "active"
                      : currentQuestion > 1
                      ? "done"
                      : null
                  }`}
                ></li>
                <li
                  className={`stepBullet ${
                    currentQuestion === 2
                      ? "active"
                      : currentQuestion > 2
                      ? "done"
                      : null
                  }`}
                ></li>
                <li
                  className={`stepBullet ${
                    currentQuestion === 3
                      ? "active"
                      : currentQuestion > 3
                      ? "done"
                      : null
                  }`}
                ></li>
                <li
                  className={`stepBullet ${
                    currentQuestion === 4
                      ? "active"
                      : currentQuestion > 4
                      ? "done"
                      : null
                  }`}
                ></li>
                <li
                  className={`stepBullet ${
                    currentQuestion === 5
                      ? "active"
                      : currentQuestion > 5
                      ? "done"
                      : null
                  }`}
                ></li>
              </ul>
            </div>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="quiz-content"
            >
              <motion.p
                variants={child}
                className="fw-bold question-text text-dark mb-3 "
              >
                {filterCurrentQuestion.questionText}
              </motion.p>
              <motion.p
                variants={child}
                className="question-description mb-4 px-2 "
              >
                {filterCurrentQuestion.questionDescription}
              </motion.p>

              <div>
                {filterCurrentQuestion.answerOptions.map(
                  (answerOption, index) => (
                    <motion.button
                      variants={child}
                      key={answerOption.answerText}
                      className={`btn btn-question answer-text p-options mt-3 mb-1  ${
                        answerOption.answerImg
                          ? "imageText-answer"
                          : "text-answer"
                      }`}
                      onClick={() => {
                        handleAnswerOptionClick(
                          answerOption.path,
                          answerOption.productName,
                          answerOption.answerText
                        );
                      }}
                    >
                      {/* Show image if question answerImg true */}
                      {answerOption.answerImg && (
                        <img src={answerOption.answerImg} />
                      )}
                      {answerOption.answerText}
                    </motion.button>
                  )
                )}
              </div>
            </motion.div>
            <div>
              <button
                onClick={() => setCurrentQuestion(1)}
                className="btn btn-back"
              >
                <i class="bi bi-arrow-left"></i>&nbsp;Go back
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center escapebox-btn-wrapper mx-auto">
            <a
              className="btn escapebox"
              href="https://escapebox.si/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={process.env.PUBLIC_URL + "escapebox-engine-dark.svg"} />
            </a>
          </div>
        </div>
      </>
    );
  };

  const PreLoader = () => {
    return (
      <div className="preloader-container d-flex justify-content-center align-items-center">
        <div className="preloader-wrapper d-flex justify-content-center align-items-center p-3">
          <h3 className="h3-loader mb-3">
            Building the best product recommendation for you...
          </h3>
          <div className="loading-animation d-flex justify-content-center align-items-center mb-2">
            <Lottie animationData={brainyAnimation} loop={true} />
          </div>
          <small className="pt-3">This can take couple of seconds.</small>
        </div>
      </div>
    );
  };

  const ShowResults = () => {
    return (
      <div className="product-results d-flex justify-center align-items-center">
        <h3 className="mb-4 product-recommendation-title">
          You will love this recommendations!
        </h3>
        {/*
        <ul className="chosen-answers-wrapper">
          {chosenAnswer.map((spec, index) => {
            return (
              <li key={index} className="chosen-answers mx-4 mb-4">
                <i class="bi bi-check-circle-fill mx-2"></i>
                {spec}
              </li>
            );
          })}
          <div className="restart-btn"></div>
        </ul>
        */}
        <div className="row d-flex justify-content-center align-items-center bg-transparent mt-4 mb-4">
          {/* Začneš z array-om filterProducts, potem s .filter metodo prikažeš prve tri (index < 3), potem z map metodo prikažeš vse podatke ki jih želiš  */}
          {filterProducts
            .filter((item, index) => index < 3)
            .map((item, index) => {
              return (
                <Card
                  className={`card ${
                    index === 0
                      ? "order-2 order-md-1"
                      : index === 1
                      ? "order-1 order-md-2"
                      : index === 2
                      ? "order-3 order-md-3"
                      : null
                  }`}
                >
                  <Card.Img src={item.productImg} />
                  <Card.Body>
                    <Card.Title>{item.productName}</Card.Title>
                    <Card.Text>{item.productDesc}</Card.Text>
                    <a
                      className="btn btn-primary rounded-pill"
                      href={item.productLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Buy now
                    </a>
                  </Card.Body>
                </Card>
              );
            })}
        </div>
        <button
          className="btn btn-restart-quiz rounded-pill mb-4"
          onClick={() => setMainMenu(true)}
        >
          <i class="bi bi-arrow-counterclockwise"></i>&nbsp;Restart quiz
        </button>
        <div className="container-fluid p-5 bg-transparent">
          <h4>Educational content</h4>
          <div className="product-landing-wrapper d-md-flex d-sm-grid justify-content-evenly align-items-center mx-auto">
            <div className="col-lg-6 col-sm-12 my-5">
              <img className="img8" src="images/image8.png" />
            </div>
            <div className="col-lg-6 col-sm-12 text-dark text-start">
              <h5>Description title</h5>
              <p className="p-landing my-3">
                Send all quiz data to your email tools, automation mechanisms,
                CRM systems and analitical dashboards to enable you even more
                precise marketing activities.
              </p>
              <button className="btn btn-success rounded-pill text-white">
                <a
                  className="text-white text-decoration-none text-uppercase fw-bold"
                  href="https://www.shopify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add to basket
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-grape">
          <div className="grid-wrapper d-md-flex d-sm-grid justify-content-center align-items-center mx-auto my-5">
            <div className="col-lg-4 col-12 m-mobile">
              <img src="images/image10.png" />
              <p className="p-grid pt-3">
                Send all quiz data to your email<br></br>tools, automation
                mechanisms.
              </p>
            </div>
            <div className="col-lg-4 col-12 m-mobile">
              <img src="images/image11.png" />
              <p className="p-grid pt-3">
                Send all quiz data to your email<br></br>tools, automation
                mechanisms.
              </p>
            </div>
            <div className="col-lg-4 col-12">
              <img src="images/image12.png" />
              <p className="p-grid pt-3">
                Send all quiz data to your email<br></br>tools, automation
                mechanisms.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid p-5 bg-transparent">
          <div className="product-landing-wrapper d-md-flex d-sm-grid justify-content-between align-items-center bg-transparent">
            <div className="col-lg-6 col-sm-12 text-dark text-start">
              <h5>Description title</h5>
              <p className="p-landing my-4">
                Send all quiz data to your email tools, automation mechanisms,
                CRM systems and analitical dashboards to enable you even more
                precise marketing activities.
              </p>
              <button className="btn tn-lg btn-success rounded-pill text-white">
                <a
                  className="text-white text-decoration-none text-uppercase fw-bold"
                  href="https://www.shopify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add to basket
                </a>
              </button>
            </div>
            <div className="col-lg-6 col-sm-12">
              <img className="img9" src="images/image9.png" />
            </div>
          </div>
        </div>
        <div className="container-fluid bg-transparent pt-5 pb-4">
          <a
            href="https://escapebox.si/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={process.env.PUBLIC_URL + "escapebox-engine-dark.svg"} />
          </a>
        </div>
      </div>
    );
  };
  return (
    <div className="app">
      <a
        href="http://ultimate-gift-quiz.brainylab.io/"
        rel="noopener noreferrer"
      >
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "brainy_logo.svg"}
        />
      </a>

      <section
        className={`quiz d-flex justify-content-center ${
          mainMenu ? "quiz-intro" : "quiz-sub"
        }`}
      >
        <div className="text-center">
          {mainMenu ? (
            <MainMenu />
          ) : showProducts ? (
            <ShowResults />
          ) : (
            <ShowQuestions />
          )}
        </div>
      </section>
    </div>
  );
}
